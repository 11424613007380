// Material design boxshadows for cards
.card-1 {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.card-1-hover {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card-2 {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-3 {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card-4 {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-5 {
	box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

// -- box shadows

img {
	max-width: 100%;
	height: auto;
}

.img-responsive {
	max-width: 100%;
	height: auto;
}

a {
	text-decoration: none;
	transition: all .3s;
	color: $gc__content--paragraph;
	
	&:hover {
		color: $gc__global--pink;
		
		span {
			color: $gc__global--pink !important;
			transition: all .3s;
		}
	}
}

h1 {
	@include font-size(20px);
	color: $gc__content--heading;
	margin-bottom: 0;
}

h2 {
	@include font-size(18px);
	color: $gc__content--heading;
	margin-bottom: 0;
}

li {
	color: $gc__content--paragraph;
}

p {
	@include font-size(15px);
	color: $gc__content--paragraph;
	line-height: 160%;
	
	&.lead {
		@include font-size(22px);
		margin: 0;
	}
	
	strong {
		color: $gc__content--heading;
	}
}

img {
	@extend .card-1;
}

// if an anchor tag has class 'pdf-link', attach pdf icon
.pdf-link {
	background: url(../images/pdf_icon.png) no-repeat center top;
	background-size: 75px auto;
	min-width: 80px;
	padding: 90px 0 0 0;
	display: inline-block;
	text-align: center;
	
	&:hover {
		background: url(../images/pdf_icon--pink.png) no-repeat center top;
		background-size: 75px auto;
	}
}


#content {
	@include row();
}

article {
	@include row();
}
