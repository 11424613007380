#site-header {
  @include row();
  padding: 7px 5px !important;
  position: relative;
  border-bottom: 1px dashed $gc__border--grey;
	display: flex;
	flex-flow: row wrap;
	align-items: center;

  @include media($tablet) {
    padding: 20px 25px !important;
  };

  .logo__wrapper {
    @include span-columns(1);

    @include media($tablet) {
      @include row();
      @include span-columns(8);
    };

    @include media($desktop) {
      @include row();
      @include span-columns(12);
    };

    .logo {
      @extend .img-responsive;
      vertical-align: bottom;
      box-shadow: none;

      @include media($tablet) {
        width: auto;
        float: left;
      };
    }
  } // --- logo__wrapper

  .site__slogan {
    color: $gc__content--heading;
    font-family: $oswald;
    text-transform: uppercase;
    @include font-size(15px);
    letter-spacing: 0.6px;
	  padding-top: 5px;
    text-align: center;
	  flex: 1;

    @include media($tablet) {
	    @include font-size(17px);
      text-align: left;
      padding-top: 10px;
    };
  }
}




// responsive menu
.menu__wrapper {
  position: absolute;
  right: 3%;

  @include media($desktop) {
    bottom: 13%;
  };

  .responsive-menu-toggle-icon {
    display: block;
    height: 26px;
    width: 60px;
    position: relative;

    @include media($desktop) {
      display: none;
    };

    &:after {
      background: $gc__global--pink;
      top: 21px;
      width: 30px
    }

    &:before {
      background: $gc__global--pink;
      top: 7px;
      width: 30px
    }

    .icon {
      background: $gc__global--pink;
      width: 30px
    }

    .label {
      display: none;
    }
  }

  .block-superfish {
    display: none;
    font-family: $raleway;

    @include media($desktop) {
      display: block;
    };

    ul {

      li {
        margin-left: 25px;

        a {
          color: $gc__menu--grey;

          // .sf-sub-indicator {
          //   background: url(../../../../libraries/superfish/images/arrows-777777.png) no-repeat -10px -100px;
          //   right: -15px;
          //   top: 5px;
          // }

          &:hover {
            color: $gc__menu--pink;
          }
        }

        &.sfHover {

          ul {
            @extend .card-1;
            border-top: 0;
            border-radius: 0;
            background: #fff;
            padding: 5px 15px !important;
            min-width: 100px !important;
            width: auto;

            li {
              padding: 0 !important;
              margin: 0;
              border-bottom: 1px dashed $gc__border--grey;

              &:last-child {
                border: 0;
              }

              a {
                padding: 15px 10px;
                @include font-size(15px);
              }
            }
          }
        }
      }
    }
  }
}

.mm-page {
  background-color: transparent;
  height: 100%;
}

html.mm-opening .responsive-menu-toggle-icon span.icon {
  left: 13px;
  transition: ease .4s;
  opacity: 0;
}
