body {
  margin: 0;
  padding: 0;

  // includes container for site elements
  #site-header,
  #featured,
  #site-footer {
    @include container;
  }

  main {
    @include container;
  }

  .block {
    @include row();
  }

  &.no-sidebars {
    main {
      section#content {
        @include row();
        @include span-columns(4);

        @include media($tablet) {
          @include span-columns(8);
        };

        @include media($desktop) {
          @include span-columns(12);
        };
      }
    }
  }

  &.one-sidebar.sidebar-first {
    main {
      @include row();

      aside {
        &#sidebar-first {
          @include span-columns(4);

          @include media($tablet) {
            @include span-columns(2);
            @include shift(-8);
          };

          @include media($desktop) {
            @include span-columns(3);
            @include shift(-12);
          };
        }
      }

      section#content {
        @include span-columns(4);

        @include media($tablet) {
          @include span-columns(6);
          @include shift(2);
        };

        @include media($desktop) {
          @include span-columns(9);
          @include shift(3);
        };
      }
    }
  }

  &.one-sidebar.sidebar-second {
    main {
      @include row();

      aside {
        &#sidebar-second {
          @include span-columns(4);

          @include media($tablet) {
            @include span-columns(2);
          };

          @include media($desktop) {
            @include span-columns(3);
          };
        }
      }

      section#content {
        @include span-columns(4);

        @include media($tablet) {
          @include span-columns(6);
        };

        @include media($desktop) {
          @include span-columns(9);
        };
      }
    }
  }

  &.two-sidebars {
    main {
      @include row();

      aside {
        &#sidebar-first {
          @include span-columns(4);

          @include media($tablet) {
            @include span-columns(2);
            @include shift(-6);
          };

          @include media($desktop) {
            @include span-columns(3);
            @include shift(-9);
          };
        }

        &#sidebar-second {
          @include span-columns(4);

          @include media($tablet) {
            @include span-columns(2);
          };

          @include media($desktop) {
            @include span-columns(3);
          };
        }
      }

      section#content {
        @include span-columns(4);

        @include media($tablet) {
          @include span-columns(4);
          @include shift(2);
        };

        @include media($desktop) {
          @include span-columns(6);
          @include shift(3);
        };
      }
    }
  }

  #site-footer {
    @include row();

    section#footer-top {
      @include span-columns(4);

      @include media($tablet) {
        @include span-columns(8);
      };

      @include media($desktop) {
        @include span-columns(12);
      };
    }

    section#footer-bottom {
      @include span-columns(4);

      @include media($tablet) {
        @include span-columns(8);
      };

      @include media($desktop) {
        @include span-columns(12);
      };
    }
  }

}
