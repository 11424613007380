@import "base";

// make the visual grid follow the container
@if ($visual-grid) {
	body:before {
		@include container;
	}
}

// removes box shadow on drupal admin menu
#admin-menu {
	box-shadow: none;
}

.page-app,
.page-app-send {
	background: none;
	
	#site-header {
		display: none;
	}
	
	.page {
		box-shadow: none;
		@include outer-container(810px)
	}
}

/* ==========================================================================
   																	App style
   ========================================================================== */
::selection {
	background-color: #d4d4d4;
}

html {
	width: 100%;
	height: 100%;
}

body {
	color: #333;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22));
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
}

.page {
	position: relative;
}

main {
	margin: 30px 0 40px 0;
	
	@include media($desktop) {
		margin: 40px 0 60px 0;
	}
;
	
	#content {
		font-family: $questrial;
		
		@include media($desktop) {
			padding-bottom: 165px;
		}
	;
	}
}

// content wrapper
.page {
	@include outer-container(1050px);
	@extend .card-3;
	height: auto;
	min-height: 100%;
	background-color: #fff;
	border-bottom: 1px solid #e2e2e2;
	border-top: 1px solid #ebebeb;
}

// welcome text on front page

#node-1 {
	@include row();
	margin-bottom: 40px;
	font-family: $raleway;
	
	@include media($desktop) {
		@include span-columns(8);
		@include shift(2);
		float: none;
	};
	
	h1 {
		font-weight: 100;
		@include font-size(35px);
	}
	
	p {
		font-weight: 300;
	}
}

// block latest cards om front page

.view-frontpage-cards {
	
	.view-content {
		
		@include media($desktop) {
			@include span-columns(8);
			@include shift(2);
		};
		
		.views-row {
			@include span-columns(1.333);
			@include omega(3n);
			margin-bottom: 20px;
			position: relative;
			
			.field__card-wrapper {
				@include overlay-transition;
				
				img {
					max-width: 100%;
					height: auto;
				}
			}
			
			@include media($tablet) {
				@include span-columns(2);
				@include omega-reset(3n);
				@include omega(4n);
			};
		}
	}
}
	


.view-send-card {
	
	.view-filters {
		margin-bottom: 50px;
		
		#edit-tid-wrapper {
			
			label {
				@include font-size(20px);
				color: $gc__content--heading;
				margin-bottom: 30px;
			}
			
			.views-widget {
				
				@include media($tablet) {
					display: inline-block;
				}
			;
			}
			
			.description {
				margin-top: 30px;
				color: $gc__global--pink;
				@include font-size(15px);
				
				@include media($tablet) {
					display: inline-block;
					margin-left: 20px;
					margin-top: 0;
				}
			;
			}
		}
	}
	
	.full-image-wrapper {
		width: 100%;
		text-align: center;
		
		.full-image {
			display: inline-block;
			@include overlay-transition;
			
			& span {
				@include font-size(30px);
			}
			
			img {
				margin: 0 auto;
				display: block;
				max-width: 100%;
				height: auto;
				max-height: 35vh;
				cursor: pointer;
			}
		}
	}
	
	.owl-carousel {
		padding: 0;
		margin-top: 50px;
		
		.owl-wrapper-outer {
			
			.owl-wrapper {
				
				.owl-item {
					cursor: pointer;
					margin-right: 20px;
					@include overlay-transition;
					
					img {
						max-width: 100%;
						height: auto;
					}
				}
			}
		}
		
		.owl-controls {
			
			.owl-buttons {
				margin-top: 10px;
				
				.owl-prev,
				.owl-next {
					background: transparent;
					color: $gc__content--heading;
					@include font-size(16px);
					opacity: 1;
					border-radius: 0;
					padding: 5px 15px;
					
					&:hover {
						background: $gc__global--pink;
						color: #fff;
					}
				}
			}
		}
	}
}

.view-showroom {
	@include row();
	
	.views-exposed-form {
		margin-bottom: 50px;
	}
	
	.view-content {
		
		.views-row {
			@include span-columns(1.3333333);
			@include omega(3n);
			margin-bottom: 30px;
			
			@include media($tablet) {
				@include span-columns(2);
				@include omega-reset(3n);
				@include omega(4n);
			}
		;
			
			.field__card-wrapper {
				position: relative;
				@include overlay-transition;
				
				img {
					@extend .img-responsive;
				}
				
				span {
					
					@include media($desktop) {
						@include font-size(25px);
					}
				;
				}
				
				&:hover {
					
					& span,
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
}

// formatting an ul class of pdf-list
ul.pdf-list {
	@include row();
	padding: 0;
	margin: 40px 0;
	display: flex;
	flex-flow: row wrap;
	
	@include media($tablet) {
		max-width: 100%;
	};
	
	li {
		list-style: none;
		margin-bottom: 30px;
		text-align: center;
		width: 30%;
		@include span-columns(1.333333);
		@include omega(3n);
		@include word-wrap;
		
		@include media($tablet) {
			@include omega-reset(3n);
			@include span-columns(1.6);
			@include omega(5n);
		};
	}
}

// view: kontakt page -- medarbejdere
.view-person {
	
	.view-content {
		@include row;
		
		.employee {
			@include span-columns(2);
			@include omega(2n);
			text-align: center;
			margin-bottom: 45px;
			
			@include media($tablet) {
				@include omega-reset(2n);
				@include span-columns(2.666666666666);
				@include omega(3n);
				text-align: left;
			}
		;
			
			@include media($desktop) {
				@include omega-reset(2n);
				@include omega-reset(3n);
				@include span-columns(3);
				@include omega(4n);
			}
		;
			
			&--meta {
				margin-top: 10px;
				
				@include media($tablet) {
					margin-left: 5px;
				}
			;
				
				.views-field {
					margin: 5px 0;
				}
			}
		}
	}
}

// view: inspiration og showroom
.view-inspiration-and-showroom {
	
	.inspiration-showroom-row {
		@include row();
		margin-bottom: 75px;
		
		.field-showroom {
			
			&-image {
				@include media($tablet) {
					@include span-columns(3);
				}
			;
				
				@include media($desktop) {
					@include span-columns(5);
				}
			;
			}
			
			&-meta {
				margin-top: 0;
				
				@include media($tablet) {
					@include span-columns(5);
				}
			;
				
				@include media($desktop) {
					@include span-columns(7);
				}
			;
			}
		}
	}
}
