#site-footer {
	border-top: 1px dashed #959595;

	@include media($desktop) {
		height: 165px;
		@include position(absolute, null 0 0 0);
	};


	#footer-top {
		padding: 10px 0 25px 0;

		.footer__menu {

			ul {
				padding: 0;

				li {
					@include span-columns(2);
					@include omega();
					list-style: none;
					display: inline-block;
					text-align: center;
					position: relative;
					transition: all .3s;
					margin-bottom: 45px;

					@include media($mobile-landscape) {
						@include span-columns(2);
						@include omega();
						margin-bottom: 45px;
					};

					@include media($tablet) {
						@include span-columns(1.7);
						@include omega();
						margin-bottom: 0;
					};

					@include media($desktop) {
						@include span-columns(2.4);
						@include omega();
						margin-bottom: 0;
					};

					a {
						padding-top: 80px;
						font-size: 12px;
						color: $gc__footer--items;
						background-size: 80px auto !important;
						display: block;

						&:hover {
							color: $gc__global--pink;
						}

						// find os på facebook
						&.menu-356 {
							background: url(../images/footer__logos/facebook.png) no-repeat center top;

							&:hover {
								background: url(../images/footer__logos/facebook--hover.png) no-repeat center top;
							}

							&.active-trail {
								background: url(../images/footer__logos/facebook--hover.png) no-repeat center top;
								color: $gc__global--pink;
							}
						}

						// send et elektronisk go-card
						&.menu-357 {
							background: url(../images/footer__logos/papirfly.png) no-repeat center top;

							&:hover {
								background: url(../images/footer__logos/papirfly--hover.png) no-repeat center top;
							}

							&.active-trail {
								background: url(../images/footer__logos/papirfly--hover.png) no-repeat center top;
								color: $gc__global--pink;
							}
						}

						// tilmed dig vores nyhedsbrev
						&.menu-358 {
							background: url(../images/footer__logos/nyhedsbrev.png) no-repeat center top;

							&:hover {
								background: url(../images/footer__logos/nyhedsbrev--hover.png) no-repeat center top;
							}

							&.active-trail {
								background: url(../images/footer__logos/nyhedsbrev--hover.png) no-repeat center top;
								color: $gc__global--pink;
							}
						}

						// køb et originalt go-card stativ
						&.menu-359 {
							background: url(../images/footer__logos/stativ.png) no-repeat center top;

							&:hover {
								background: url(../images/footer__logos/stativ--hover.png) no-repeat center top;
							}

							&.active-trail {
								background: url(../images/footer__logos/stativ--hover.png) no-repeat center top;
								color: $gc__global--pink;
							}
						}

						// inspiration og showroom
						&.menu-410 {
							background: url(../images/footer__logos/inspiration.png) no-repeat center top;

							&:hover {
								background: url(../images/footer__logos/inspiration--hover.png) no-repeat center top;
							}

							&.active-trail {
								background: url(../images/footer__logos/inspiration--hover.png) no-repeat center top;
								color: $gc__global--pink;
							}
						}
					}
				}
			}
		}
	}
}
