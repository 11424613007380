form.send-et-go-card {
  @extend .card-1;
  padding: 15px;
  margin-top: 50px;

  @include media($tablet) {
    box-shadow: none;
    padding: 0;
  };

  p {
    color: $gc__global--pink;
    font-size: 16px;
    font-family: $questrial;
    margin: 5 0 10px 0;

    @include media($desktop) {
      font-size: 14px;
    };
  }

  input.form-control {
    @extend .send-et-go-card__placeholder;
    width: 100%;
    margin: 0 0 15px 0;
    border: 1px solid #d6d6d6;
    box-shadow: none;

    &:focus {
      outline: none;
    }
  }

  textarea {
    @extend .send-et-go-card__placeholder;
    width: 100%;
    resize: none;
    height: 70px;
    border: 1px solid #d6d6d6;

    &:focus {
      outline: none;
    }
  }

  input.button {
    background: #fff;
    border: 1px solid #d6d6d6;
    font-size: 14px;
    padding: 10px 25px;
    color: $gc__form--placeholder;
    font-family: $questrial;

    &:hover {
      background: $gc__global--pink;
      color: #fff;
      border-color: $gc__global--pink;
    }
  }

  #form-card-nid {
    visibility: hidden;
  }
}


.send-et-go-card__placeholder {
  padding: 5px 5px;
  font-size: 13px;
  color: #333;

  &::placeholder {
    color: $gc__form--placeholder;
  }
}



// mailchimp nyhedsbrev signup
.mailchimp-form {
  @extend .card-1;
  padding: 25px;
  background: #f5f5f5;


  .mailchimp-signup-subscribe-form-description {
    @extend h1;
    margin-bottom: 40px;
  }

  label {
    margin-left: 2px;
  }

  input {
    padding: 5px 10px;
    margin-top: 8px;
    height: 34px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF;

    &:focus {
      outline: none;
    }

    &.form-submit {
      box-shadow: none;
      border: none;
      width: 50%;
      background: #fff;
      border: 1px solid #d6d6d6;
      font-size: 14px;
      padding: 10px 25px;
      color: $gc__form--placeholder;
      font-family: $questrial;

      &:hover {
        background: $gc__global--pink;
        color: #fff;
        border-color: $gc__global--pink;
      }

      @include media($tablet) {
        width: 15%;
      };
    }
  }
}
