@import "../../bower_components/neat/app/assets/stylesheets/_neat-helpers.scss";


// Default grid settings

$column:                90px;
$gutter:                30px;
$grid-columns:          4;
$grid-max-width:        930px;


// Visual grid settings

$visual-grid:           false;
$visual-grid-color:     red;
$visual-grid-index:     front;
$visual-grid-opacity:   0.1;


// Mobile first breakpoint variables

$sm:                    568px;  // 568px  -- Mobile phone - landscape
$md:                    768px;  // 768px  -- Medium screen - tablet
$lg:                    992px;  // 992px  -- Large screen / desktop
$xl:                    1200px; // 1200px -- Extra large screen / wide desktop


// Extra small devices (portrait phones, less than 34em)
// No media query since this is mobile first

$mobile-landscape:      new-breakpoint(min-width $sm 6);

// Medium devices (tablets, 48em and up)
$tablet:                new-breakpoint(min-width $md 8);

// Large devices (desktops, 62em and up)
$desktop:               new-breakpoint(min-width $lg 12);

// Extra large devices (large desktops, 75em and up)
$desktop-xl:            new-breakpoint(min-width $xl 12);


// Container mixin
@mixin container {
    @include outer-container($grid-max-width);
    @include pad(0 25px);
}
