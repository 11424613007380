// Raleway
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,300);

// Questrial
@import url(https://fonts.googleapis.com/css?family=Questrial);

// Oswald
@import url(https://fonts.googleapis.com/css?family=Oswald);

// font families
$raleway:               'Raleway', sans-serif;
$questrial:             'Questrial', sans-serif;
$oswald:                'Oswald', sans-serif;


// colors
$gc__global--pink:       #ec008c;

$gc__menu--pink:         #cc0587;

$gc__menu--grey:         #888888;

$gc__content--heading:   #222222;

$gc__content--paragraph: #222222;

$gc__form--placeholder:  #585858;

$gc__footer--items:      #888888;

$gc__border--grey:       #959595;
