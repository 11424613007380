// ---------------------------------------------
// Creates a dark overlay that disappears on hover
// ---------------------------------------------
@mixin overlay-transition {
	position: relative;
	
	&::after {
		content: "";
		@include position(absolute, 0 0 0 0);
		transition: background .7s cubic-bezier(.25, .8, .25, 1);
		pointer-events: none;
	}
	
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: all .6s ease-out;
		color: #fff;
		z-index: 6;
		overflow: hidden;
		pointer-events: none;
	}
	
	&:hover {
		& span {
			opacity: 1;
		}
		
		&::after {
			background: transparentize(#000000, 0.3);
		}
	}
}

// ---------------------------------------------
// Scss mixin and function to use rem units with pixel fallback.
// ---------------------------------------------
@function calculateRem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}



@mixin word-wrap {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}
